import { Colors } from './types';
import { globalSystemColors } from './constants';

export const darkColors: Colors = {
  text: {
    primary: '#2f1f19',
    secondary: '#98918a',
    basic: '#505050',
    platinum: '#7a66de',
    silver: '#888686',
    gold: '#9c8651'
  },
  background: {
    primary: '#fff',
    secondary: '#f6f4f1',
    tertiary: '#f8f8f8'
  },
  border: { primary: '#eae5e1' },
  systemColors: {
    ...globalSystemColors,
    brand: '#946952',
    lightBlue: '#dae8ff',
    lightRed: '#ffe6e5'
  },
  promotion: {
    bump: '#78c91d',
    vip: '#ff3d00',
    premium: '#ff9f2b'
  }
};
